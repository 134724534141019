import styled from 'styled-components';

import { ScTitleProps } from '../../components/Title/styled';

export const ScErrorLayout = styled.div`
	min-height: 100vh;
	background: ${({ theme }) => theme.colors.marmelade};
	display: flex;
	flex-direction: column;
	gap: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 20px;
	> div {
		display: flex;
		flex-direction: column;
		gap: 30px;
	}
`;

export const ScLargeTitle = styled.h1<ScTitleProps>`
	color: ${({ theme, color }) => color || theme.colors.darkText};
	text-align: ${({ centered }) => centered && 'center'};
	margin: 0;
	letter-spacing: -3.534px;
	font: ${({ theme }) => theme.fonts.largeHeading.tiny};
	@media (min-width: 360px) {
		font: ${({ theme }) => theme.fonts.largeHeading.tiny};
	}
	@media (min-width: 510px) {
		font: ${({ theme }) => theme.fonts.largeHeading.small};
	}
	@media (min-width: 768px) {
		font: ${({ theme }) => theme.fonts.largeHeading.mediumLarge};
	}
	@media (min-width: 1440px) {
		font: ${({ theme }) => theme.fonts.largeHeading.xl};
		letter-spacing: -9.6px;
	}
	@media (min-width: 1920px) {
		font: ${({ theme }) => theme.fonts.largeHeading.xxl};
	}
`;

export const ScErrorLayoutTextWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 30px;
	align-items: center;
	max-width: 555px;
	margin: 0 auto 20px auto;
	h5 {
		font: ${({ theme }) => theme.fonts.paragraph.xl};
		font-weight: 700;
		font-family: GeomanistMedium;
		color: ${({ theme }) => theme.colors.strongWhite};
		margin: 0;
		text-align: center;
		@media (max-width: 400px) {
			font: ${({ theme }) => theme.fonts.heading.xs};
		}
	}
	p {
		font: ${({ theme }) => theme.fonts.button.large};
		font-weight: 400;
		color: ${({ theme }) => theme.colors.strongWhite};
		margin: 0;
		text-align: center;
		font-family: Geomanist, serif;
		@media (max-width: 400px) {
			font: ${({ theme }) => theme.fonts.button.medium};
			font-family: Geomanist, serif;
		}
	}
`;

export const ScErrorLayoutImageWrapper = styled.div`
	position: relative;
	margin: 40px auto 0 auto;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 30px;

	img {
		width: 100%;
		height: auto;
		z-index: 5;
		@media (min-width: 360px) {
			width: auto;
			height: 180px;
		}
		@media (min-width: 500px) {
			width: auto;
			height: 220px;
		}
		@media (min-width: 768px) {
			width: auto;
			height: 429px;
		}
		@media (min-width: 1440px) {
			width: auto;
			height: 481px;
		}
		@media (min-width: 1920px) {
			width: auto;
			height: 590px;
		}
		@media (min-width: 1220px) and (max-height: 900px) {
			height: 350px;
		}
	}

	h1 {
		position: absolute;
		z-index: 2;
	}

	@media (min-width: 360px) {
		margin-top: 0;
	}
`;
